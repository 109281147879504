import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { MDXLayout as PageLayout } from "../components/blocks/mdx-layout";
import { SEO } from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Appearances" description="Over the last couple of years I've given various talks and demos at conferences & meetups. In this overview you can find them all, plus links to slides & videos." breadcrumbListItems={[{
      name: `Appearances`,
      url: `/appearances`
    }]} mdxType="SEO" />

    <h1 {...{
      "id": "appearances"
    }}>{`Appearances`}</h1>
    <p>{`Over the last couple of years I’ve given lightning & full-length talks at conferences & meetups. If you see 🎬 a video is available, otherwise 📖 indicates that the slides are linked.`}</p>
    <h2 {...{
      "id": "2022"
    }}>{`2022`}</h2>
    <ul>
      <li parentName="ul">{`🎬 `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=oOjLJivLEoA"
        }}>{`Becoming an Open Source Champion with Gatsby`}</a>{` at `}<a parentName="li" {...{
          "href": "https://gatsbyconf.com"
        }}>{`GatsbyConf 2022`}</a></li>
    </ul>
    <h2 {...{
      "id": "2021"
    }}>{`2021`}</h2>
    <ul>
      <li parentName="ul">{`🎬 `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=X3haR60VjZc"
        }}>{`Parallel Query Running with Gatsby 4`}</a>{` at `}<a parentName="li" {...{
          "href": "https://www.gatsbyjs.com/camp-fall-2021/"
        }}>{`GatsbyCamp Fall ‘21`}</a></li>
      <li parentName="ul">{`🎬 `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=58OD42jyF4o"
        }}>{`Build an E-Commerce Proof of Concept in One Hour`}</a>{` on Twitch`}</li>
      <li parentName="ul">{`🎬 `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=tO-5qFa_hH8"
        }}>{`Introducing: Gatsby v3`}</a>{` at `}<a parentName="li" {...{
          "href": "https://www.gatsbyconf.com/"
        }}>{`GatsbyConf 2021`}</a></li>
    </ul>
    <h2 {...{
      "id": "2020"
    }}>{`2020`}</h2>
    <ul>
      <li parentName="ul">{`🎬 `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=vDYwOOXlGjw"
        }}>{`Getting Started with Gatsby`}</a>{` at `}<a parentName="li" {...{
          "href": "https://www.gatsbyjs.com/gatsby-coding-careers/"
        }}>{`Coding and Careers: Getting Started with Gatsby`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/LekoArts/gatsby-webinar-getting-started"
        }}>{`Demo`}</a>{`.`}</li>
      <li parentName="ul">{`🎬 `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=RLxYuRmTq8c"
        }}>{`Composable Building Blocks for the Web: An Introduction to Gatsby Themes`}</a>{` at `}<a parentName="li" {...{
          "href": "https://2020.decoupleddays.com/session/composable-building-blocks-web-introduction-gatsby-themes"
        }}>{`Decoupled Days 2020`}</a></li>
      <li parentName="ul">{`🎬 `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=p-TyigucHcU"
        }}>{`Internationalization with Gatsby`}</a>{` at `}<a parentName="li" {...{
          "href": "https://www.gatsbyjs.com/blog/2020-06-23-reconfiguring-gatsby-days/"
        }}>{`Virtual Gatsby Days 2020`}</a></li>
    </ul>
    <h2 {...{
      "id": "2019"
    }}>{`2019`}</h2>
    <ul>
      <li parentName="ul">{`📖 `}<a parentName="li" {...{
          "href": "https://github.com/LekoArts/talks/blob/main/composable-building-blocks-for-the-web/composable-building-blocks-for-the-web.pdf"
        }}>{`Composable Building Blocks for the Web: An Introduction to Gatsby Themes`}</a>{` at `}<a parentName="li" {...{
          "href": "https://www.meetup.com/de-DE/DarmstadtJS/events/266285684/"
        }}>{`DarmstadtJS`}</a></li>
      <li parentName="ul">{`📖 `}<a parentName="li" {...{
          "href": "https://github.com/LekoArts/talks/blob/main/gatsbys-open-source-philosophy/Gatsbys-Open-Source-Philosophy.pdf"
        }}>{`Gatsby’s open source philosophy: “You Belong Here”`}</a>{` at `}<a parentName="li" {...{
          "href": "https://www.eventbrite.com/e/hacktoberfest-frankfurt-2019-tickets-71269742719#"
        }}>{`Hacktoberfest Frankfurt`}</a></li>
      <li parentName="ul">{`📖 `}<a parentName="li" {...{
          "href": "https://github.com/LekoArts/talks/blob/main/gatsby-more-than-a-ssg/Gatsby-More-Than-A-Static-Site-Genator.pdf"
        }}>{`Gatsby - More than a Static Site Generator`}</a>{` at `}<a parentName="li" {...{
          "href": "https://www.meetup.com/de-DE/React-Meetup-Mannheim/events/260338372/"
        }}>{`3. React Meetup Mannheim`}</a>{`, `}<a parentName="li" {...{
          "href": "https://www.meetup.com/de-DE/Frontend-Rhein-Neckar/events/259360008/"
        }}>{`Frontend Rhein-Neckar #5`}</a>{`, `}<a parentName="li" {...{
          "href": "https://www.meetup.com/de-DE/Incloud-Tech-Night/events/259269967/"
        }}>{`Incloud TechNight | React`}</a>{`, and `}<a parentName="li" {...{
          "href": "https://www.meetup.com/de-DE/React-Meetup-Frankfurt/events/255647987/"
        }}>{`React Meetup - Frankfurt & Rhein-Main`}</a></li>
    </ul>
    <h2 {...{
      "id": "2018"
    }}>{`2018`}</h2>
    <ul>
      <li parentName="ul">{`📖 `}<a parentName="li" {...{
          "href": "https://github.com/LekoArts/talks/blob/main/css-custom-properties-intro/CSS-Custom-Properties-Intro.pdf"
        }}>{`CSS Custom Properties: Intro`}</a>{` at `}<a parentName="li" {...{
          "href": "https://cssfrankfurt.de/"
        }}>{`cssfrankfurt`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      